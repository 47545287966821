import { SliderContainer } from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import React from 'react';
import { generateKey } from 'utils/helpers';

import * as S from './styles';

type Slide = {
  image: string | React.ReactNode;
  imageAlt: string;
  title: string;
  eyebrow?: string;
  copy: string;
  subtitle: string;
};

export interface SliderSectionProps {
  headline: string;
  slides: Slide[];
  uid?: string;
  backgroundColor?: 'Primary' | 'Secondary';
  className?: string;
}

export const SliderSection: React.FC<SliderSectionProps> = ({
  uid = `slider-${generateKey()}`,
  className,
  backgroundColor,
  headline,
  slides,
}) => (
  <SectionWrapper
    data-testid={`${uid}-section`}
    className={className}
    backgroundColor={backgroundColor}
  >
    <S.Container>
      <SliderContainer title={headline} slideData={slides} />
    </S.Container>
  </SectionWrapper>
);

export default SliderSection;
