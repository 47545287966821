import { size, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const Container = styled.div`
  ${mediaQueries.forTabletHorizontalUp} {
    padding-left: ${size.lg}px;
    padding-right: ${size.lg}px;
  }
`;

export { Container };
