import { SliderSection } from 'components/PageSections/SliderSection';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import React from 'react';

import { Slide } from '../../../utils/types';

export interface SliderContainerProps {
  content: {
    slug: string;
    headline: string;
    backgroundColor?: 'Primary' | 'Secondary';
    className?: string;
    listOfContentWithImages: Slide[];
  };
}

export const SliderContainer: React.FC<SliderContainerProps> = ({
  content,
}) => {
  if (!content) {
    return null;
  }

  const {
    headline,
    listOfContentWithImages,
    backgroundColor,
    className,
    slug,
  } = content;

  const formattedListOfContent = listOfContentWithImages.map((item) => ({
    title: item.heading,
    eyebrow: item.title,
    copy: item.description.description,
    image: (
      <Img
        image={item.image.gatsbyImageData}
        style={{ width: '100%' }}
        alt=""
      />
    ),
    imageAlt: item.image.description || '',
    subtitle: item.subheading,
  }));

  return (
    <SliderSection
      headline={headline}
      backgroundColor={backgroundColor}
      className={className}
      uid={slug}
      slides={formattedListOfContent}
    />
  );
};

export default SliderContainer;
